<template>
    <div class="popup-view">
        <CardComponent>
            <template #header>
                <RowComponent align="center">
                    <HelpComponent
                        markdown="embed-popup"
                        external-link="https://www.surveylegend.com/user-guide/embed-surveys-forms-or-polls-as-a-popup/"
                    />
                    <span>Popup embed code</span>
                </RowComponent>
            </template>
            <RowComponent justify="space-between" align="center">
                <RowComponent align="center">
                    <HelpComponent
                        markdown="transparent-iframe"
                        external-link="https://www.surveylegend.com/user-guide/embed-surveys-forms-website-blog/#transparent-background"
                    />
                    <span>Title</span>
                </RowComponent>
                <EditComponent
                    v-model="survey.name"
                    placeholder="Your feedback is appreciated!"
                    @save="
                        (value) => {
                            handleSave('survey', { name: value })
                        }
                    "
                />
            </RowComponent>
            <RowComponent justify="space-between" align="center">
                <RowComponent align="center">
                    <HelpComponent
                        markdown="transparent-iframe"
                        external-link="https://www.surveylegend.com/user-guide/embed-surveys-forms-website-blog/#transparent-background"
                    />
                    <span>Position</span>
                </RowComponent>
                <RadioGroupComponent>
                    <RadioComponent value="theme-classic"></RadioComponent>
                    <RadioComponent value="theme-classic"></RadioComponent>
                    <RadioComponent value="theme-classic"></RadioComponent>
                    <RadioComponent value="theme-light"></RadioComponent>
                    <RadioComponent value="theme-dark"></RadioComponent>
                </RadioGroupComponent>
            </RowComponent>
            <RowComponent justify="space-between" align="center">
                <RowComponent align="center">
                    <HelpComponent
                        markdown="transparent-iframe"
                        external-link="https://www.surveylegend.com/user-guide/embed-surveys-forms-website-blog/#transparent-background"
                    />
                    <span>Size</span>
                </RowComponent>
                <RadioGroupComponent>
                    <RadioComponent value="theme-light">Small</RadioComponent>
                    <RadioComponent value="theme-dark">Medium</RadioComponent>
                    <RadioComponent value="theme-dark">Large</RadioComponent>
                </RadioGroupComponent>
            </RowComponent>
            <DividerComponent />
            <RowComponent justify="space-between" align="center">
                <RowComponent align="center">
                    <HelpComponent
                        markdown="transparent-iframe"
                        external-link="https://www.surveylegend.com/user-guide/embed-surveys-forms-website-blog/#transparent-background"
                    />
                    <span>Popup after scroll</span>
                </RowComponent>
                <ToggleComponent
                    v-model="survey.iframeTransparentBackground"
                    prevent
                    @change="handleChange($event, 'iframeTransparentBackground')"
                />
            </RowComponent>
            <RowComponent justify="space-between" align="center">
                <RowComponent align="center">
                    <HelpComponent
                        markdown="transparent-iframe"
                        external-link="https://www.surveylegend.com/user-guide/embed-surveys-forms-website-blog/#transparent-background"
                    />
                    <span>Popup with delay</span>
                </RowComponent>
                <ToggleComponent
                    v-model="survey.iframeTransparentBackground"
                    prevent
                    @change="handleChange($event, 'iframeTransparentBackground')"
                />
            </RowComponent>
            <PrismComponent
                :code="survey.iframeTransparentBackground ? embedCodeTransparent : embedCode"
                language="markup"
            >
                <template #toolbar>
                    <CopyComponent
                        :value="
                            survey.iframeTransparentBackground ? embedCodeTransparent : embedCode
                        "
                        size="small"
                        translucent
                    />
                </template>
            </PrismComponent>
        </CardComponent>
    </div>
</template>

<script>
export default {
    name: 'Popup',

    metaInfo: {
        title: 'Popup'
    },

    data() {
        return {
            survey: {}
        }
    }
}
</script>

<style scoped lang="scss">
.popup-view {
    .card-component__content > .row-component {
        margin-bottom: 20px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    .prism-component {
        margin-top: 25px;
    }
}
</style>
